<!-- AboutPage.vue -->
<template>
  
  <div class="p-4 flex flex-row flex-wrap">
    <div class="w-1/3 w-auto h-auto py-2 px-2">
      <a href="https://soundcloud.com/xenokino" class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">My own music</h5>
        <p class="font-normal text-gray-700 dark:text-gray-400">Here is my collection of publicized music I felt was appropriate to share.</p>
      </a>
    </div>
      <!-- <p class="text-base">
      </p> -->
    <iframe class="w-1/3 h-auto py-4 pr-4" scrolling="no" frameborder="yes" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1677535836&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=true&show_teaser=true"></iframe>
    <iframe class="w-1/3 h-auto py-4 pr-4" scrolling="no" frameborder="yes" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1485305401&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=true&show_teaser=true"></iframe>
    <iframe class="w-1/3 h-auto py-4 pr-4" scrolling="no" frameborder="yes" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1583469547&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=true&show_teaser=true"></iframe>
    <iframe class="w-1/3 h-auto py-4 pr-4" scrolling="no" frameborder="yes" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1520801497&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>
    <iframe class="w-1/3 h-auto py-4 pr-4" scrolling="no" frameborder="yes" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1575395887&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>
    <iframe class="w-1/3 h-auto py-4 pr-4" scrolling="no" frameborder="yes" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1247424991&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>
    <iframe class="w-1/3 h-auto py-4 pr-4" scrolling="no" frameborder="yes" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1365209446&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>
    <iframe class="w-1/3 h-auto py-4 pr-4" scrolling="no" frameborder="yes" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1192206217&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>
  </div>
  </template>
  
  <script>
  export default {
    name: 'AboutPage', // Name the component
    data() {
      return {
        // Add any data properties needed for this component
      }
    },
    methods: {
      // Add any methods needed for this component
    },
    // Add any other component options as required
  }
  </script>

<style scoped>
</style>
  
  