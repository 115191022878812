<template>
  <div>
    <canvas :id="canvasId"></canvas>
  </div>
</template>

<script>
import * as THREE from 'three';

export default {
  name: 'SkyScene',
  props: {
    setting: {
      type: String,
      default: () => 'clear',
    },
  },
  data() {
    return {
      canvasId: `canvas-${Math.floor(Math.random() * 1000000)}`, // Unique ID for canvas
      canvas: null,      
      scene: null,
      renderer: null,
      camera: null,
      controls: null,
      testVars: {}
    };
  },
  mounted() {
    this.canvas = document.getElementById(this.canvasId);
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
    });

    const width = this.canvas.clientWidth;
    const height = this.canvas.clientHeight;
    const aspectRatio = width / height;
    this.renderer.setSize(width, height);
    const maxPixelRatio = Math.min(window.devicePixelRatio, 2);
    this.renderer.setPixelRatio(maxPixelRatio);

    this.initScene(aspectRatio, this.canvas);
    window.addEventListener('resize', () => this.onWindowResize());
  },
  methods: {
    async initScene(aspectRatio) {

      // Init Scene
      const scene = new THREE.Scene();

      // Init Camera
      this.camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 10000);
      this.camera.position.set(0, 0, 0);


      // Condition to determine which skyBox class to instantiate
      const condition = this.$props.setting; // Replace this with your actual condition
      // const skyTextures = require.context('../skyboxes/', true, /\.(png)$/);

      let skyBox;

      switch (condition) {
        case 'clear': {
          const { default: ClearOcean } = await import('../skyboxes/clearOcean.js');
          skyBox = new ClearOcean();
          break;
        }
        case 'orange': {
          const { default: ApocOcean } = await import('../skyboxes/apocalypseOcean.js');
          skyBox = new ApocOcean();
          break;
        }
        case 'dusk': {
          const { default: DuskOcean } = await import('../skyboxes/duskOcean.js');
          skyBox = new DuskOcean();
          break;
        }
        default: {
          const { default: DefaultSkyOcean } = await import('../skyboxes/clearOcean.js');
          skyBox = new DefaultSkyOcean();
          break;
        }
      }

      scene.add(skyBox);


      // Render the scene
      const animate = () => {
        requestAnimationFrame(animate);
        skyBox.rotation.y += 0.005; 

        this.renderer.render(scene, this.camera);
      };
      animate();
    },
    onWindowResize() {

      console.log("Resizing window")
      const canvasWidth = this.canvas.clientWidth;
      const canvasHeight = this.canvas.clientHeight;

      // Get the available window width and height
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Calculate the new dimensions based on the minimum of canvas size or window size
      const newWidth = Math.min(canvasWidth, windowWidth);
      const newHeight = Math.min(canvasHeight, windowHeight);
      this.renderer.setSize(newWidth, newHeight);
      this.camera.aspect = newWidth / newHeight;
      this.camera.updateProjectionMatrix();
    },
  },
};
</script>