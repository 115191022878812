<template>
    <div class="flex justify-around ">
      <SkyBox :setting="'clear'" ref="skyBox"/>
      <SkyBox :setting="'orange'" ref="skyBox"/>
      <SkyBox :setting="'dusk'" ref="skyBox"/>
    </div>
</template>


<script>
// import SceneFrame from './SceneFrame.vue';
import SkyBox from './threejs/scenes/SkyScene';

export default {
  name: 'GalleryPage',
  data() {
    return {
    };
  },
  components: {
    // SceneFrame
    SkyBox
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style scoped>
</style>
