<template>
  <div class="flex flex-col h-screen">
    
    <!-- Header -->
    <MainTitle/>

    <!-- Content -->
    <main class="flex-1 flex lg:overflow-y-auto lg:p-8">
      <!-- For smaller screens -->
      <div class="lg:hidden w-full py-4 px-4">
        <NavBar class="border border-blue-800 rounded-xl mb-4 py-4 px-8"/>
        <router-view/>
      </div>

      <!-- For larger screens -->
      <div class="hidden align-items-center justify-items-center lg:flex flex-1 flex-row">
        <div class="w-40 px-4 py-2">
          <div class="bg-opacity-50 border border-blue-800 rounded-xl py-4 px-4">
            <NavBar />
          </div>
        </div>
        <div class="w-3/5 h-full p-4">
          <router-view/>
        </div>
      </div>
    </main>


  </div>
</template>

<script>
import MainTitle from './components/MainTitle.vue';
import NavBar from './components/NavBar.vue'
import router from './router'

export default {
  name: 'App',
  components: {
    NavBar,
    MainTitle
},
  router,
}
</script>

<style>
/* Apply a basic reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
