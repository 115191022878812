<template>
  <div class="p-4">
    <p class="text-base">A heavily personalized collection of my being featuring digital artwork, music, and code I'm proud of.</p>
    <p class="text-base">As I continue to grow as a hardware and software engineer, I see the general user become more naive.</p>
    <p class="text-base">I believe producing with ethics, accountability, and accessibilty in mind is paramount.</p>
    <p class="text-base">I will do everything I can to maintain that.</p>

    <div>
      <a href="https://www.linkedin.com/in/xavier-esteve" class="linkedin-button" target="_blank" rel="noopener noreferrer">
      Visit my LinkedIn profile
      </a>

      <a href="https://github.com/XavierJEsteve" class="github-button" target="_blank" rel="noopener noreferrer">
        <i class="fab fa-github"></i> Visit my GitHub profile
      </a>

      <!-- Email me button -->
      <a :href="emailLink" class="email-button" target="_blank" rel="noopener noreferrer">
        Email me
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  computed: {
    emailLink() {
      const user = 'xjesteve'; // Your email handle
      const domain = 'gmail.com'; // Your domain
      return `mailto:${user}@${domain}`;
    },
  },
};
</script>

<style scoped>
.linkedin-button,
.email-button ,
.github-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  margin-right: 1rem;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition on hover */
}
.linkedin-button {
  background-color: #0077B5; /* LinkedIn blue */
  color: white;
}

.github-button {
  background-color: #24292e; /* GitHub black */
  color: white;
}
.email-button {
  background-color: #B4241C; /* GitHub black */
  color: white;
}

.email-button:hover {
  background-color: #711510; /* Darker shade of Gmail red on hover */
}

.linkedin-button:hover {
  background-color: #005f91; /* Darker shade of LinkedIn blue on hover */
}

.github-button:hover {
  background-color: #181818; /* Darker shade of GitHub black on hover */
}

/* Adjust icon size for GitHub button */
.github-button i {
  margin-right: 5px;
  font-size: 18px;
}
</style>
