<template>
  <!-- Header -->
  <header class="bg-blue-500 text-white py-4 px-8 text-left flex items-center justify-center lg:justify-start">
    <!-- Canvas Container -->
    <div class="flex-col justify-end items-center sm:items-center lg:items-center sm:text-center lg:text-left">
      <canvas ref="canvas"></canvas>
      <p class="text-sm">A conversation starter</p>
      <p class="text-base font-light">Xavier Jose Esteve</p>
    </div>
  </header>
</template>


<script>
export default {
  name: 'MainTitle',
  mounted() {
    const canvas = this.$refs.canvas;
    const ctx = canvas.getContext('2d');

    // Define animation properties
    let phase = 0;
    const speed = 0.03;
    const frequency = 20;

    // Animation function
    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Sine wave
      ctx.beginPath();
      ctx.moveTo(0, canvas.height / 2);
      for (let i = 0; i < canvas.width; i++) {
        ctx.lineTo(i, canvas.height / 2 + Math.sin(i / frequency + phase) * 50);
      }
      ctx.strokeStyle = 'blue';
      ctx.stroke();

      // Background for text
      const text = 'DIGILOGUE';
      ctx.font = 'bold 40px Futura';
      const textWidth = ctx.measureText(text).width;
      const paddingX = 5;
      const paddingY = 5;
      const rectWidth = textWidth + 2 * paddingX;
      const rectHeight = 30; // Fixed height for text background
      ctx.fillStyle = 'rgb(63, 131, 248)';
      ctx.fillRect(
        canvas.width / 2 - rectWidth / 2,
        canvas.height / 2 - paddingY - rectHeight / 2,
        rectWidth, 
        rectHeight
      );

      // Text
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.fillText('DIGILOGUE', canvas.width / 2, canvas.height / 2 + 5); // 5 is the vertical offset
      ctx.stroke();

      // Update phase for animation
      phase += speed;

      // Animation loop
      requestAnimationFrame(draw);
    };

    // Start the animation
    draw();
  },
};
</script>

<style scoped>
/* Apply scoped styles here */
</style>
