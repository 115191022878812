<template>
  <div class="w-auto">
    <nav id="nav">
      <!-- For smaller screens, show NavBar items horizontally -->
      <div class="flex flex-wrap justify-center align-center flex-center lg:flex-col">
        <router-link to="/" class="font-bold mb-2 lg:mb-4 lg:mr-0 mx-2">Home</router-link>
        <!-- <router-link to="/hire-me" class="font-bold mb-2 lg:mb-4 lg:mr-0 mx-2">Hire Me!</router-link> -->
        <router-link to="/tunes" class="font-bold mb-2 lg:mb-4 lg:mr-0 mx-2">Music</router-link>
        <router-link to="/3d-work" class="font-bold mb-2 lg:mb-4 lg:mr-0 mx-2">Three.js</router-link>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
}
</script>

<style scoped>
/* Apply specific styles if needed */
</style>
