import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
// import HireMe from './components/HireMe.vue';
import MusicPage from './components/MusicPage.vue';
import GalleryPage from './components/GalleryPage.vue';

const routes = [
  { path: '/', component: HomePage },
  // { path: '/hire-me', component: HireMe },
  { path: '/tunes', component: MusicPage },
  { path: '/3d-work', component: GalleryPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
